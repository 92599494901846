<template>
	<v-row xl="6" md="8" sm="12" cols="12" justify="center" class="mt20 mb20">
		<v-col xl="6" md="6" sm="12" cols="12">
			<h1 class="title-report-test mb20">
				Rapport personnel de
				<span class="ortho-text-green">{{ user.first_name }}</span>
			</h1>
			<v-divider></v-divider>
			<v-col xl="12" md="12" sm="12" cols="12">
				<h1 class="mt20">Ton score :</h1>
				<div class="ortho-bold ortho-results-score mb30 animate__animated animate__jackInTheBox">
					{{ testScore.score }}%
				</div>
				<v-chip color="#15c39a" text-color="white">
					<v-icon size="20px" color="white">mdi-information-outline</v-icon>
					<h4 class="ml5">Tu fais mieux que {{ percentileScore }}% des utilisateurs</h4>
				</v-chip>
			</v-col>
			<img class="image-results-report" src="@/assets/images/testpositionscore.png" alt="" />

			<v-row>
				<v-col cols="12" sm="6" md="3" class="d-flex">
					<v-card class="pa-4 text-center flex-grow-1 d-flex align-center justify-center shadow-blue-not-openned">
						<div>
							<div class="number-detail-score font-weight-bold ortho-text-green">{{ goodAnswers.length }}</div>
							<div class="text-subtitle-1 grey--text">
								{{ goodAnswers.length > 1 ? 'Bonnes réponses' : 'Bonne réponse' }}
							</div>
						</div>
					</v-card>
				</v-col>

				<v-col cols="12" sm="6" md="3" class="d-flex">
					<v-card class="pa-4 text-center flex-grow-1 d-flex align-center justify-center shadow-blue-not-openned">
						<div>
							<div class="number-detail-score font-weight-bold ortho-text-red">{{ badAnswers.length }}</div>
							<div class="text-subtitle-1 grey--text">
								{{ badAnswers.length > 1 ? 'Mauvaises réponses' : 'Mauvaise réponse' }}
							</div>
						</div>
					</v-card>
				</v-col>

				<v-col cols="12" sm="6" md="3" class="d-flex">
					<v-card class="pa-4 text-center flex-grow-1 d-flex align-center justify-center shadow-blue-not-openned">
						<div>
							<div class="number-detail-score font-weight-bold">{{ unansweredQuestionsCount }}</div>
							<div class="text-subtitle-1 grey--text">
								{{ unansweredQuestionsCount > 1 ? 'Sans réponses' : 'Sans réponse' }}
							</div>
						</div>
					</v-card>
				</v-col>

				<v-col cols="12" sm="6" md="3" class="d-flex">
					<v-card class="pa-4 text-center flex-grow-1 d-flex align-center justify-center shadow-blue-not-openned">
						<div>
							<div class="number-detail-score font-weight-bold">
								{{ sortedTestTrackByPosition ? sortedTestTrackByPosition.length : '0' }}
							</div>
							<div class="text-subtitle-1 grey--text">Total des questions</div>
						</div>
					</v-card>
				</v-col>
			</v-row>

			<div
				v-if="testScore && testScore.topic_slug === 'orthographe'"
				class="ortho-results-text container-explication shadow-blue pt20 pb20"
			>
				<h2 class="mb30">Ton résultat à la loupe 🔎</h2>
				<p v-if="testScore.score >= 90">
					« {{ user.first_name }}, avec une note globale de {{ testScore.score }}%,
					<strong>tu as un niveau parfait d’employabilité vis-à-vis de vos écrits</strong>
					: tu maîtrises l’ensemble des règles de grammaire et d’orthographe ! Tu n’as pas un réel besoin de formation.
					Tu es très sûrement LA référence -pas seulement de ton entreprise- mais de tout ton entourage en la matière,
					chapeau l’artiste ! »
				</p>
				<p v-else-if="testScore.score >= 70">
					« {{ user.first_name }}, avec une note globale de {{ testScore.score }}%,
					<strong>tu as un niveau solide d’employabilité vis-à-vis de l’orthographe</strong>
					: tu maîtrises l’essentiel des règles de grammaire et d’orthographe et nul doute que tu ne laisses passer que
					peu d’erreurs dans tes écrits professionnels. Tu as néanmoins toujours une marge de progression à ta
					disposition pour devenir performant à l’écrit : en effet, certaines règles et exceptions de la langue
					française semblent encore t’échapper, mais plus pour longtemps »
				</p>
				<p v-else-if="testScore.score >= 50">
					« {{ user.first_name }}, avec une note globale de {{ testScore.score }}%,
					<strong>tu as un niveau normal d’employabilité vis-à-vis de l’orthographe</strong>
					: tu maîtrises les règles basiques de grammaire et d’orthographe. Tu as tout de même une importante marge de
					progression à ta disposition pour devenir performant à l’écrit : certaines règles et exceptions de la langue
					française semblent encore t'échapper, mais plus pour longtemps »
				</p>
				<p v-else>
					« {{ user.first_name }}, avec une note globale de {{ testScore.score }}%,
					<strong>tu as un niveau d’aptitude fragile en orthographe</strong>. Tu maîtrises les règles basiques de
					grammaire mais l’orthographe est souvent pour toi plus affaire d’instinct que de certitude… Cela peut donc te
					mener à la faute ! Tu as ainsi une importante marge de progression à ta disposition pour devenir performant à
					l’écrit. Certaines règles et exceptions de la langue vous échappent, mais plus pour longtemps »
				</p>
				<div id="chart" class="mt20 text-center">
					<TestReportRadarChart
						v-if="sortedTestTrackByPosition && testScore"
						:testTracks="sortedTestTrackByPosition"
						:testScore="testScore"
					/>
				</div>
			</div>

			<FloatingComponentToFirstExercise v-if="this.showFloatingStartFormation" />
			<div class="correction-container pa15">
				<UserAnswersDetail
					v-if="testBank.user_side_report_test_answers_visibility === true"
					:testTracks="sortedTestTrackByPosition"
					:testScore="testScore"
				/>
				<div class="hvr-grow ortho-next-button-nobackground mb20" v-scroll-to="{ el: '#top', offset: -200 }">
					Revenir en haut de page
					<v-icon class="ortho-icon-black fs15 ml5">fas fa-arrow-up</v-icon>
				</div>
			</div>
			<div
				v-if="!showFloatingStartFormation"
				id="scroll-target-button-floating"
				class="text-center hidden-button-floating button-floating"
			>
				<router-link :to="toTestHomePageRouterObj">
					<v-btn color="black" large dark class="hvr-grow">
						<strong>Voir tous mes défis</strong>
						<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
					</v-btn>
				</router-link>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import UserAnswersDetail from './UserAnswersDetail.vue';
import TestReportRadarChart from './TestReportRadarChart.vue';
import FloatingComponentToFirstExercise from '@/components/positionnement/FloatingComponentToFirstExercise.vue';
import { sortObjArr } from '@/helpers/array.js';

export default {
	name: 'TestReportComponent',
	components: {
		UserAnswersDetail,
		TestReportRadarChart,
		FloatingComponentToFirstExercise,
	},
	props: {
		testBank: Object,
		user: Object,
		testScore: Object,
		testTracks: Array,
		showFloatingStartFormation: Boolean,
	},
	computed: {
		sortedTestTrackByPosition() {
			return sortObjArr(this.testTracks, 'ASC', 'position', 'number');
		},
		unansweredQuestionsCount() {
			if (!this.sortedTestTrackByPosition) return 0;
			const count = this.sortedTestTrackByPosition.length - this.goodAnswers.length - this.badAnswers.length;
			return Math.max(0, count); // Retourne 0 si le count est négatif
		},
		percentileScore() {
			const score = this.testScore.score;
			if (score >= 90) return score - 7;
			if (score >= 70) return score - 6;
			if (score >= 50) return score - 5;
			if (score >= 30) return score - 4;
			if (score >= 10) return score - 3;
			return score;
		},
		goodAnswers() {
			return this.testScore.user_answers.filter((userAnswer) => userAnswer.score === 100);
		},
		badAnswers() {
			return this.testScore.user_answers.filter(
				(userAnswer) => userAnswer.score !== undefined && userAnswer.score !== null && userAnswer.score < 100
			);
		},
		toTestHomePageRouterObj() {
			if (this.$route.query.testHomePageTopicSlugQuery) {
				return { name: 'TestHomePage', query: { topicSlug: this.$route.query.testHomePageTopicSlugQuery } };
			}
			if (this.testScore.topic_slug) {
				return { name: 'TestHomePage', query: { topicSlug: this.testScore.topic_slug } };
			}
			return { name: 'TestHomePage' };
		},
	},

	data() {
		return {
			stepProgressBar: 100,
			stepProgressBarBufferValue: 80,
			stepProgressBarLimit: 90,
		};
	},
	async mounted() {
		this.startBuffer(); //start progress bar animation
		window.scrollTo(0, 0); //always display top of the page

		document.addEventListener('scroll', () => {
			const scrollTarget = document.getElementById('scroll-target-button-floating');
			if (!scrollTarget) return;
			const scrollPosition = window.scrollY + window.innerHeight;
			const pageHeight = document.documentElement.scrollHeight;

			if (scrollPosition >= pageHeight * 0.5) {
				scrollTarget.classList.remove('hidden-button-floating');
				scrollTarget.classList.add('visible-button-floating');
			} else {
				scrollTarget.classList.remove('visible-button-floating');
				scrollTarget.classList.add('hidden-button-floating');
			}
		});
	},
	methods: {
		startBuffer() {
			setInterval(() => {
				this.stepProgressBarBufferValue = this.stepProgressBarLimit;
			}, 500);
		},
	},
};
</script>
<style scoped>
.row {
	margin: 0 !important;
}
.ortho-next-button-nobackground {
	cursor: pointer;
}

.image-results-report {
	margin-top: 70px;
	margin-bottom: 40px;
	width: 100%;
}

.startnow {
	color: black;
}

.container-explication {
	line-height: 2;
	border-radius: 5px;
	border: 2px solid #15c39a;
	margin-top: 50px;
	margin-bottom: 40px;
	padding-left: 10%;
	padding-right: 10%;
}

.hidden-button-floating {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s ease, visibility 0.5s ease;
}

.visible-button-floating {
	opacity: 1;
	visibility: visible;
	transition: visibility 0.5s ease, opacity 0.5s ease;
}

.correction-container {
	margin-bottom: 40px;
}

.number-detail-score {
	font-size: 30px;
}

@media only screen and (max-width: 600px) {
	.image-results-report {
		margin-top: 70px;
		margin-bottom: 70px;
		width: 90%;
	}
	.number-detail-score {
		font-size: 25px;
	}
}
@media only screen and (max-width: 325px) {
	h3 {
		font-size: 13px;
	}
}
</style>
