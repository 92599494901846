/**
 *
 * @param {Array} unsorted
 * @param {'DESC' | 'ASC'} order
 * @param {string} sortByField
 * @param {'string' | 'number' | 'daytime'} valueType
 * @returns
 */
export const sortObjArr = (unsorted, order, sortByField, valueType) => {
	try {
		if (unsorted.length === 0 || unsorted.length === 1) return unsorted;

		let sorted = [];

		if (valueType === 'number') {
			sorted = unsorted.sort((a, b) => {
				if (a[sortByField] == null) return order === 'ASC' ? 1 : -1;
				if (b[sortByField] == null) return order === 'ASC' ? -1 : 1;
				return order === 'ASC' ? a[sortByField] - b[sortByField] : b[sortByField] - a[sortByField];
			});
		} else if (valueType === 'string') {
			sorted = unsorted.sort((a, b) => {
				if (a[sortByField] === b[sortByField]) return 0;
				if (a[sortByField] === null) return order === 'ASC' ? -1 : 1;
				if (b[sortByField] === null) return order === 'ASC' ? 1 : -1;
				return order === 'ASC'
					? a[sortByField].localeCompare(b[sortByField])
					: b[sortByField].localeCompare(a[sortByField]);
			});
		} else if (valueType === 'daytime') {
			sorted = unsorted.sort((a, b) => {
				if (a[sortByField] == null) return order === 'ASC' ? 1 : -1; // Move null to the end
				if (b[sortByField] == null) return order === 'ASC' ? -1 : 1; // Move null to the end
				return order === 'ASC'
					? new Date(`${a[sortByField]}`).getTime() - new Date(`${b[sortByField]}`).getTime()
					: new Date(`${b[sortByField]}`).getTime() - new Date(`${a[sortByField]}`).getTime(); // Sort by timestamp
			});
		}

		return sorted;
	} catch (error) {
		return unsorted;
	}
};
